<template>
	<div style="margin-top: 20px;">
		<div v-if="isMobileLayout" class="footer">
			<div>
				<a href="/pages/about">About Us</a> | <a href="/pages/terms">Terms of Use</a> | <a href="/pages/privacy">Privacy</a> | <a href="/pages/contact">Contact Us</a>
			</div>
			<div class="right"><p>Copyright © 2024 All rights Reserved</p></div>
		</div>
		<div v-else class="footer" style="display: flex; align-items: center; justify-content: center;">
			<p style="margin-right: 20px;">Copyright © 2024 All rights Reserved</p>
			<a href="/pages/about">About Us</a> | <a href="/pages/terms">Terms of Use</a> | <a href="/pages/privacy">Privacy</a> | <a href="/pages/contact">Contact Us</a>
		</div>
	</div>
</template>

<script>
	export default {
		inject: ['app'],
		computed: {
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		}
	}
</script>

<style>
</style>