<template>
	<div class="flex-1" style="padding-bottom: 0px;">
		<GameHeader />
		<router-view />
		<GameFooter />
	</div>
</template>

<script>
	import GameHeader from '@/components/common/game-header'
	import GameFooter from '@/components/common/game-footer'
	import {
		mapGetters
	} from 'vuex'

	export default {
		name: 'home-layout',
		components: {
			GameHeader,
			GameFooter
		},
		computed: {
			...mapGetters({
				// _navBar: state => state.menu,
			}),
		}
	}
</script>

<style>
</style>