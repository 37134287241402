<template>
	<div style="width: 100%;">
		<div v-if="isMobileLayout" style="z-index: 10; position: relative; width: 100%; height: 100%;">
			<div class="mo-header">
				<div class="header-logo" style="flex: 1 1 0%; margin: 0px; width: 60px;">
					<a href="/">
						<img src="@/assets/logo/index.png" alt="logo" style="width: 40px;">
					</a>
				</div>
				<div class="header-btns" style="width:calc(100% - 120px);">
					<div class="search-box"><input class="search-input" placeholder="Search" type="text" v-model="searchText">
					<img class="search-icon" src="@/assets/img/search-mo.svg" alt="">
						<ul v-if="!showMobileCategories" class="search-item-box" :style="customStyle">
							<li v-for="game in searchRusult" :key="game.handle">
								<a class="search-item" :href="'/game/' + game.handle">{{game.title}}</a>
							</li>
						</ul>
					</div>
				</div>
				<div data-v-ad54570e="" class="mo-fav-play" style="width: 60px;">
					<div data-v-ad54570e="" class="mo-menu" @click="showMobileCategories=!showMobileCategories">
						<img data-v-ad54570e="" src="@/assets/img/menu.svg" alt="menu" width="25">
					</div>
					<ul v-if="showMobileCategories" class="search-item-box">
						<li v-for="item in uniqueCategories" :key="item.category_handle">
							<a class="search-item" :href="'/category/' + item.category_handle">{{item.category}}</a>
						</li>
					</ul>
				</div>
			</div>
		</div>
		<div v-else style="z-index: 10; position: relative; width: 100%; height: 100%;">
			<div class="header">
				<div class="header-logo">
					<a href="/">
						<img src="@/assets/logo/wottygame.png" alt="logo" style="width: 130px;">
					</a>
				</div>
				<div style="flex: 1 1 0%;"></div>
				<div class="header-class">
					<a v-for="item in uniqueCategories" class="header-center"
						:href="'/category/' + item.category_handle" :key="item.cateogory_handle">
						<div style="white-space: nowrap;">{{ item.category }} </div>
					</a>
				</div>
				<div class="header-btns">
					<div class="search-box">
						<input class="search-input" placeholder="Search" type="text" v-model="searchText">
						<img class="search-icon" src="@/assets/img/search.svg" alt="">
						<ul class="search-item-box" :style="customStyle">
							<li v-for="game in searchRusult" :key="game.handle">
								<a class="search-item" :href="'/game/' + game.handle">{{game.title}}</a>
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapGetters,
		mapState
	} from 'vuex'
	export default {
		inject: ['app'],
		computed: {
			...mapGetters({
				uniqueCategories: 'games/uniqueCategories',
			}),
			...mapState({
				games: state => state.games.games
			}),
			searchRusult() {
				// 将 searchText 转换为小写并去除空格
				const normalizedSearchText = this.searchText.toLowerCase().replace(/\s+/g, '')
				if (normalizedSearchText === '') return []
				return this.games.filter(game => game.title.toLowerCase().replace(/\s+/g, '').includes(
					normalizedSearchText))
			},
			customStyle() {
				return this.searchRusult.length > 0 ? '' : 'display:none;'
			},
			isMobileLayout() {
				return this.app.isMobileLayout
			}
		},
		data() {
			return {
				searchText: '',
				showMobileCategories: false
			}
		}
	}
</script>

<style scoped>
	.mo-top-main-dialog {
	    position: fixed;
	    z-index: 99;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    background: #0000009c;
	    touch-action: none
	}
	
	.mo-top-main-wrap {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -moz-box-orient: vertical;
	    -moz-box-direction: normal;
	    flex-direction: column;
	    height: 80%;
	    background: -webkit-gradient(linear,left top,left bottom,from(#8364da),to(#f4b7f9));
	    background: -webkit-linear-gradient(#8364da,#f4b7f9);
	    background: linear-gradient(#8364da,#f4b7f9)
	}
	
	.mo-top-main {
	    padding: 10px;
	    padding-top: 30px;
	    display: grid;
	    grid-template-columns: repeat(4,1fr);
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px
	}
	
	.mo-top-main-item {
	    padding: .3125rem 0;
	    border-radius: .625rem;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
	    color: #936de4;
	    font-size: 10px;
	    background-color: #f2e0f8;
	    cursor: pointer;
	    width: 100%;
	    text-align: center
	}
	
	.mo-top-span {
	    padding: 0 .5rem;
	    white-space: nowrap;
	    overflow: hidden;
	    font-weight: 700;
	    display: block
	}
	
	.mo-top-bottom {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    padding: 10px;
	    padding-bottom: 20px
	}
	
	.mo-top-close {
	    width: 40px;
	    height: 40px;
	    padding: 10px;
	    border-radius: 9999px;
	    box-shadow: 0 2px 10px 0 rgba(0,0,0,.25);
	    background-color: #f2e0f8
	}
	
	.header-center-bottom {
	    display: none;
	    color: #000;
	    position: absolute
	}
	
	.mo-fav-play {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-justify-content: space-around;
	    justify-content: space-around;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center
	}
	
	.mo-menu {
	    background: #f2e0f8;
	    border: 1px solid #936de4;
	    padding: 2px;
	    border-radius: 9999px
	}
	
	.right {
	    font-size: 10px;
	    text-align: center
	}
	
	.right p {
	    margin: 0;
	    line-height: 1.5
	}
	
	.footer {
	    overflow: hidden;
	    padding: 10px;
	    color: #913bc6;
	    font-size: 9px;
	    text-align: center;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#ecb1f6),to(#8866db));
	    background-image: -webkit-linear-gradient(#ecb1f6,#8866db);
	    background-image: linear-gradient(#ecb1f6,#8866db);
	    bottom: 0;
	    width: 100%
	}
	
	.footer a {
	    margin: 0 8px;
	    line-height: 20px
	}
	
	.back-top {
	    position: fixed;
	    z-index: 4;
	    bottom: 86px;
	    right: 10px;
	    width: 40px;
	    height: 40px;
	    border-radius: 50%;
	    box-shadow: 2px 0 5px rgba(0,0,0,.3);
	    background: #c392eb url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAIAAAACACAYAAADDPmHLAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAR+SURBVHgB7d1fqN9zHMfx1w9lhYSyCxQpqZVIFCVHUaJsIdLaLJpYRFj+RCaS0ZGsk5E1f+bfyAn5N6lzI25R7rhwYzduXO1i22evT99zsbO2nd+f75/P9/d+Purdp3XOWb/f5/0679/vfP+cIwEAAAAAgCgGy31CMqGvFgaDwXXH+4QThNAIQHAEIDgCEBwBCI4ABEcAgiMAwRGA4AhAcAQgOAIQHAEIjgAERwCCO2mIz9mipdcNpMV/Jx39eoJjffzw6wra/PhyXwcAAIJJKd3hWiHE48avTZWvXGcJcbjhG9JSP7nOE6afG70xHd0vrouF6eUGb0rH97vrcmH6uLGPpOH85bpGmB5u6OY0mn9dNwr950Y+ncbzv+s2ob/cwOfSZA641gn948a9mOpzv9AfbtjWVL/HhPK5Ua+l5jwrlMsN2paa97JQHjdme2rPNqEcbsiO1L4dQvfciPdTdz5xcXlZV7z5H6fufe06VWiPN/xE1+epHPl08kqhed7oFa4vU3l+dV0oNMcbfJrr21SuP1yXCPXzxp7p+jGV72/XVUJ9vKErXQupP/a6rlcPFH9rmDfyXC9fuK5Vf+Q3hPN+7GtUuKJ/hvUGXuDlU9cV6qd8G9q6wWDwoQpV7ARw8y9S9Z3f1+Zn+Rtsl5/LfSpUkQHwhq1S1fxLNR3e8nN6VAUqLgDeqNz0edcqTZfZVODp5KLeA3iD8rjf7Tpf02ur3xM8qUIUEwA3/2pVzT9H02/OIXhQBSgiAG7+jKp3+2crjp0OwT3qWOcBcPNvUNX8MxTPbofgTnWo0wC4+Tepan7k06nfuNY4CPvVgc5+CnDzV6v6US/6ufSbXXu8H6erA50EwE/2dlXNP1nI8h92yiFo/Q1w6wHwk7zLy2fiV9Qd6UrXD4tHQFvTahP85NZ7+Ug4lnzw63vv02VqSWsB8JO618t7wnLyCbAcglZuUW8lAKm6t+4dYVj5eMh3qYVb1BsPgJ/EQ17eFEZ1iitf/tboLeqNBmDxDNgbwrjycZp89fPdakhjAfCDfsLLrFCHd72fm9SARgLgB/uMF26irNec93WzalZ7APwgn/fygtCEV7y/W1SjWs8F+MG95OUpoWmzPnfwuGpQWwDc/Fe91PKgMJTtDsEDmlAtAXDzX/fysNC2DxyC9ZrAxAFw8+e8NPIOFUOZdwhu1ZgmCoCb/7aXjULX9rhWOwj7NKKxA+Dm7/SyQSjFz6pC8N8oXzRWANz8XV7WCqX5zXWLQ/DPsF8wcgDc/K4u3pxRv+TjIQdcB2uuYf7PvQ7Bn0M8xqH+bNwSXVzEuHhwaUY9470q/oBYX67K4RcxNaQvAeDysYYwAYJjAgTHBAiOCRAcAQiOl4DgmADBMQGCYwIExwQIjgkQHAEIjpeA4JgAwTEBgmMCBMcECI4JEBwTIDgmQHAEIDheAoJjAgTHBAiOCRAcEyA4JkBwBCA4XgKCYwIExwQIjgkQHBOgOUk9wAQIjgA0pxdTi5eA4JgAwY38ewI7kifAggAAAAAAADC2QzxH7I+G3TK/AAAAAElFTkSuQmCC) no-repeat 50%/20px
	}
	
	.game-itemss {
	    display: inline-block;
	    cursor: pointer;
	    width: 100%;
	    overflow: hidden;
	    height: 87px;
	    border-radius: 4px
	}
	
	.game-false {
	    height: 130px
	}
	
	.gane-name {
	    overflow: hidden;
	    margin: .35714em;
	    color: #4a4a4a;
	    font-size: .875em;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    text-align: center;
	    font-family: Dosis
	}
	
	.game-item-div {
	    height: 122px;
	    width: 144px;
	    -webkit-transition: .2s;
	    transition: .2s;
	    border-radius: 10px;
	    overflow: hidden;
	    position: relative;
	    display: block;
	    padding: .125em;
	    border: 2px solid #fff;
	    border-radius: 5px;
	    background: #fff;
	    text-decoration: none;
	    -webkit-transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    transition: box-shadow .45s cubic-bezier(.23,1,.32,1) 0ms;
	    box-shadow: 0 1px 6px 0 rgba(50,50,50,.45);
	    margin: 0
	}
	
	.game-item-icon {
	    position: absolute;
	    height: 100%;
	    -o-object-fit: cover;
	    object-fit: cover
	}
	
	.game {
	    padding: 5px 10px 10px 10px;
	    -webkit-transition: .2s;
	    transition: .2s;
	    text-align: center;
	    color: #fff
	}
	
	.game-min {
	    padding: 2px 10px 3px 10px
	}
	
	.game-bottom {
	    font-weight: 500;
	    font-size: 13px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    margin: 1px
	}
	
	.game-bottom-play {
	    font-size: 11px;
	    margin-top: 7px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-justify-content: space-around;
	    justify-content: space-around
	}
	
	.game-bottom-favorites {
	    cursor: pointer
	}
	
	@media (max-width: 540px) {
	    .game-itemss {
	        height:106px;
	        padding-bottom: 100%
	    }
	
	    .game {
	        padding: 1px 10px 2px 10px
	    }
	}
	
	.pc-flex {
	    margin: 16px auto 1px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex
	}
	
	.detail-class {
	    min-height: 250px
	}
	
	.mo-det-ad1 {
	    min-height: 250px;
	    margin-top: 10px;
	    margin-bottom: 10px;
	    background: -webkit-gradient(linear,left top,left bottom,from(#6c9bfb),to(#8f85ee));
	    background: -webkit-linear-gradient(#6c9bfb,#8f85ee);
	    background: linear-gradient(#6c9bfb,#8f85ee)
	}
	
	.pc-list {
	    display: grid;
	    grid-template-columns: repeat(1,130px);
	    grid-gap: 10px;
	    margin: 0 10px;
	    height: -webkit-fit-content;
	    height: -moz-fit-content;
	    height: fit-content
	}
	
	.pc-list-bottom {
	    display: grid;
	    grid-template-columns: repeat(auto-fill,145px);
	    grid-gap: 10px;
	    padding: 0;
	    border-radius: 10px 10px 0 0
	}
	
	.load-description {
	    margin: 10px 0;
	    border-radius: 10px;
	    padding: 25px;
	    color: #fff;
	    font-size: 13px;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#7299f9),to(#c47fef));
	    background-image: -webkit-linear-gradient(#7299f9,#c47fef);
	    background-image: linear-gradient(#7299f9,#c47fef);
	    line-height: 22px;
	    height: 250px
	}
	
	.load-more-class {
	    border-radius: 0 0 10px 10px;
	    margin: 0 auto;
	    height: 90px;
	    padding-top: 20px
	}
	
	.pc-ad {
	    width: 305px;
	    min-height: 250px;
	    margin-left: 11px
	}
	
	.center-bottons {
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#e2b6ec),to(#faf1fc));
	    background-image: -webkit-linear-gradient(#e2b6ec,#faf1fc);
	    background-image: linear-gradient(#e2b6ec,#faf1fc);
	    width: 100%;
	    color: #000
	}
	
	.center-bottons-mo,.center-bottons {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    padding: 10px;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center
	}
	
	.center-bottons-mo {
	    border-radius: 10px;
	    margin: 10px
	}
	
	.game-cate-bottom {
	    display: block;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#7c8af0),to(#a67eeb));
	    background-image: -webkit-linear-gradient(#7c8af0,#a67eeb);
	    background-image: linear-gradient(#7c8af0,#a67eeb);
	    color: #fff;
	    border-radius: 5px;
	    width: -webkit-fit-content;
	    width: -moz-fit-content;
	    width: fit-content;
	    padding: 0 10px;
	    margin: 0 5px 5px 0
	}
	
	.tunmber-class {
	    width: 50px;
	    height: 50px;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    border-radius: 10px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    cursor: pointer;
	    -webkit-transition: all .3s ease;
	    transition: all .3s ease;
	    background: #768cf2;
	    margin-left: 10px
	}
	
	.pc-left {
	    width: 316px;
	    margin-right: 3px
	}
	
	.pc-left-main {
	    border-radius: 10px;
	    padding: 8px 0;
	    margin: 0 9px;
	    height: 577px;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#6c9bfb),to(#c47fef));
	    background-image: -webkit-linear-gradient(#6c9bfb,#c47fef);
	    background-image: linear-gradient(#6c9bfb,#c47fef)
	}
	
	.pc-center {
	    width: 765px
	}
	
	.pc-left-main-ad {
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#6c9bfb),to(#8f85ee));
	    background-image: -webkit-linear-gradient(#6c9bfb,#8f85ee);
	    background-image: linear-gradient(#6c9bfb,#8f85ee);
	    border-radius: 10px;
	    margin: 0 9px 9px;
	    height: 250px;
	    overflow: hidden
	}
	
	.center-play {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex
	}
	
	.game-bottoms {
	    font-weight: 700;
	    font-size: 18px;
	    overflow: hidden;
	    text-overflow: ellipsis;
	    white-space: nowrap;
	    color: #515be5;
	    margin: 0 30px
	}
	
	.mo-grids {
	    display: grid;
	    grid-template-columns: repeat(3,130px);
	    grid-gap: 20px;
	    margin: 10px auto;
	    grid-auto-flow: row dense;
	    width: 430px
	}
	
	@media (max-width: 425px) {
	    .mo-grids {
	        grid-template-columns:repeat(3,100px);
	        width: 340px
	    }
	}
	
	@media (max-width: 340px) {
	    .mo-grids {
	        grid-template-columns:repeat(3,80px);
	        width: 280px
	    }
	}
	
	.game-category {
	    font-size: 13px;
	    margin-top: 5px
	}
	
	.game-play-window-card {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    width: 765px;
	    height: 498px;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -moz-box-orient: vertical;
	    -moz-box-direction: normal;
	    flex-direction: column;
	    min-height: 400px
	}
	
	.game-play-window-box {
	    overflow: hidden;
	    position: relative;
	    -webkit-box-flex: 1;
	    -webkit-flex: 1;
	    -moz-box-flex: 1;
	    flex: 1;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    background-color: #000
	}
	
	.game-play-window-bar,.game-play-window-box {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    width: 100%
	}
	
	.game-play-window-bar {
	    height: 60px;
	    padding: 6px;
	    background-color: #f01553;
	    color: #fff
	}
	
	.game-play-window-bar-icon {
	    overflow: hidden;
	    width: 48px;
	    height: 48px;
	    margin-right: 8px;
	    border-radius: 8px
	}
	
	.game-title {
	    margin: 10px;
	    border-radius: 10px;
	    position: relative;
	    overflow: hidden;
	    box-shadow: 0 1px 3px 0 rgba(50,50,50,.65);
	    padding-bottom: 20px
	}
	
	.game-title-bgimg {
	    -webkit-filter: blur(20px);
	    filter: blur(20px);
	    width: 100%;
	    height: 100%;
	    position: absolute;
	    background-color: transparent;
	    background-repeat: no-repeat;
	    background-position: 50%;
	    background-size: cover
	}
	
	.game-play-window-wrap {
	    overflow: hidden;
	    position: absolute;
	    z-index: 1;
	    width: 80%;
	    text-align: center;
	    border-radius: 36px
	}
	
	.game-play-window-left {
	    padding: 0 20px
	}
	
	.game-play-window-left,.game-play-window-right {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center
	}
	
	.game-play-window-bg {
	    position: absolute;
	    left: 0;
	    top: 0;
	    width: 100%;
	    height: 100%;
	    -webkit-filter: blur(30px);
	    filter: blur(30px);
	    border-radius: 10px;
	    z-index: 1;
	    background-color: transparent;
	    background-repeat: no-repeat;
	    background-position: 50%;
	    background-size: cover
	}
	
	.game-play-window-name {
	    margin-top: 0;
	    color: #fff;
	    text-align: center;
	    -webkit-user-select: none;
	    -moz-user-select: none;
	    -ms-user-select: none;
	    user-select: none;
	    font-size: min(36px,24.3px);
	    font-family: Roboto,sans-serif!important;
	    font-weight: 500
	}
	
	.game-play-window-star {
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    height: 50px;
	    margin-right: 10px;
	    color: #fff
	}
	
	.game-play-window-star1,.game-play-window-star {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    text-shadow: 0 1px 4px #000
	}
	
	.game-play-window-star1 {
	    height: 30px
	}
	
	.game-play-window-icon {
	    overflow: hidden;
	    cursor: pointer;
	    width: 220px;
	    height: 220px;
	    margin: 10px auto;
	    border-radius: 50%
	}
	
	.game-play-window-btn {
	    overflow: hidden;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    width: 280px;
	    height: 60px;
	    margin: 10px 0;
	    font-family: Roboto,sans-serif!important;
	    border: 2px solid #fff;
	    border-radius: 40px;
	    color: #fff;
	    font-size: 28px;
	    text-align: center;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f6ebfa),to(#c776dc));
	    background-image: -webkit-linear-gradient(#f6ebfa,#c776dc);
	    background-image: linear-gradient(#f6ebfa,#c776dc);
	    cursor: pointer;
	    -webkit-transition: all .6s ease;
	    transition: all .6s ease
	}
	
	.game-play-window-btn:hover .play-amt-pc {
	    padding-left: 10px;
	    -webkit-animation: playAmt .3s infinite;
	    animation: playAmt .3s infinite
	}
	
	.game-play-window-btn:hover {
	    -webkit-transform: scale(1.2);
	    transform: scale(1.2);
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#6a90f3),to(#b47ae9));
	    background-image: -webkit-linear-gradient(top,#6a90f3,#b47ae9);
	    background-image: linear-gradient(180deg,#6a90f3,#b47ae9)
	}
	
	.large-adv-card {
	    overflow: hidden;
	    width: 300px;
	    min-height: 300px;
	    margin-bottom: 20px
	}
	
	.span-essc {
	    text-align: right
	}
	
	.load-more {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    border-radius: 10px;
	    border: 2px solid #fff;
	    padding: 8px;
	    color: #fff;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f4e3f7),to(#cd82df));
	    background-image: -webkit-linear-gradient(#f4e3f7,#cd82df);
	    background-image: linear-gradient(#f4e3f7,#cd82df);
	    width: 300px;
	    height: 50px;
	    margin: 0 auto;
	    font-size: 20px;
	    font-weight: 700;
	    cursor: pointer;
	    -webkit-transition: all .6s ease;
	    transition: all .6s ease
	}
	
	.load-more:hover {
	    -webkit-transform: scale(1.2);
	    transform: scale(1.2)
	}
	
	@media (max-width: 540px) {
	    .load-more:hover {
	        -webkit-transition:none;
	        transition: none;
	        -webkit-transform: none;
	        transform: none
	    }
	}
	
	.mask-box {
	    position: absolute;
	    z-index: 5;
	    top: 0;
	    left: 0;
	    right: 0;
	    bottom: 0;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-orient: vertical;
	    -webkit-box-direction: normal;
	    -webkit-flex-direction: column;
	    -moz-box-orient: vertical;
	    -moz-box-direction: normal;
	    flex-direction: column;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    color: #fff;
	    background: rgba(52,58,65,.7);
	    -webkit-backdrop-filter: blur(15px);
	    backdrop-filter: blur(15px);
	    font-family: Roboto,-apple-system,BlinkMacSystemFont,Segoe UI,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji,Segoe UI Symbol
	}
	
	.mask-ads-box {
	    overflow-y: auto;
	    height: 100%;
	    padding-top: 50px
	}
	
	.mask-ads {
	    overflow-y: auto;
	    position: relative;
	    width: 100%;
	    min-height: 250px;
	    text-align: center
	}
	
	.skip-btn-box {
	    position: absolute;
	    top: 0;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    width: 100%;
	    height: 50px;
	    padding: 0 8px;
	    background: #424242
	}
	
	.skip-close,.skip-tips {
	    position: absolute;
	    z-index: 9999;
	    right: 10px
	}
	
	.skip-tips {
	    padding: 8px 10px;
	    border-radius: 999px;
	    font-size: 12px;
	    background: #2f2f2f
	}
	
	.game-icon-play {
	    cursor: pointer;
	    margin: 10px;
	    height: 50px;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center
	}
	
	.game-icon-now {
	    font-weight: 700;
	    display: block;
	    text-align: center;
	    background-image: -webkit-gradient(linear,left top,left bottom,from(#f6ebfa),to(#c776dc));
	    background-image: -webkit-linear-gradient(#f6ebfa,#c776dc);
	    background-image: linear-gradient(#f6ebfa,#c776dc);
	    height: 40px;
	    line-height: 40px;
	    width: 40%;
	    margin: 10px auto;
	    border-radius: 40px;
	    border: 2px solid #fff;
	    color: #fff
	}
	
	.game-title-stats {
	    font-size: 13px;
	    color: #fff;
	    font-weight: 100;
	    text-align: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center
	}
	
	.game-title-name {
	    text-align: center;
	    font-size: 16px;
	    font-weight: 400;
	    color: #fff;
	    margin: 0
	}
	
	.ad-flex1,.ad-flex {
	    top: 20px;
	    width: 300px
	}
	
	.ad-2 {
	    border-radius: 10px;
	    background-color: #fff;
	    min-height: 600px
	}
	
	.pc-left-main-list {
	    display: grid;
	    grid-template-columns: repeat(2,145px);
	    grid-auto-rows: auto;
	    grid-auto-flow: dense;
	    grid-gap: 10px
	}
	
	.pc-left-main-title {
	    display: -webkit-box;
	    display: -webkit-flex;
	    display: -moz-box;
	    display: flex;
	    -webkit-box-pack: center;
	    -webkit-justify-content: center;
	    -moz-box-pack: center;
	    justify-content: center;
	    -webkit-box-align: center;
	    -webkit-align-items: center;
	    -moz-box-align: center;
	    align-items: center;
	    margin: 0 0 12px 5px;
	    color: #f8a6f3
	}
	
	.game-desc-text-p::-webkit-scrollbar {
	    width: 5px
	}
	
	.game-desc-text-p::-webkit-scrollbar-thumb {
	    background: #fff;
	    border-radius: 5px
	}
	
	.game-desc-text-p::-webkit-scrollbar-track {
	    background: #0085c7;
	    border-radius: 5px
	}

</style>